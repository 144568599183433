import React, { useContext } from 'react'
import { motion } from "framer-motion"
import { TitleAnimate } from '../components/TitleAnimate'
import Strassbourg1 from '../images/Strassbourg1.png'
import Strassbourg2 from '../images/Strassbourg2.png'
import Strassbourg3 from '../images/Strassbourg3.png'
import Strassbourg4 from '../images/Strassbourg4.png'

const variants = {
    moveEnvelope: (custom) => (
        {
            rotate: [0, 180, 0], transition: { duration: 2, repeat: Infinity, repeatDelay: custom * 3 }
        }
    )
}

export const Contact = () => {
    const variantsTitel = useContext(TitleAnimate)
    return (
        <>
            <div className="body-container">
                <TitleAnimate.Provider value={TitleAnimate}>
                    <motion.div className="item-0" animate='slide' variants={variantsTitel}>
                        <span className="card-item0">
                            <h1><span>Contact Page</span></h1>
                            <h6><span>Lorem ipsum dolor sit amet</span></h6>
                        </span>
                    </motion.div>
                </TitleAnimate.Provider>
                <div className="item-1">
                    <span className="card">
                        {/* <img src='../images/BackGroundBlue.png' alt='img' height={100} width={100} />     */}
                        <div className="thumb" style={{ backgroundImage: `url(${Strassbourg1})` }}></div>
                        {/* <img src={Strassbourg} alt='img' className='card-images' /> */}
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-2">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${Strassbourg2})` }}></div>
                        {/* <img src={StrassbourgFinger} alt='img' className='card-images' /> */}
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-3">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${Strassbourg3})` }}></div>
                        {/* <img src={StrassbourgMedium} alt='img' className='card-images' /> */}
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-4">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${Strassbourg4})` }}></div>
                        {/* <img src={StrassbourgSmall} alt='img' className='card-images' /> */}
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-5">
                    <span className="card">
                        <div className='envelops-contact'>
                            <svg className='filter' width='100%' height="90" viewBox='-2 -2 15 15'>
                                <motion.path d='M10 4 6 6 2 4 2 8 10 8 10 4 2 4'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px'
                                    custom={1} animate='moveEnvelope' variants={variants}
                                />
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                                <defs>
                                    <filter id="f1" x="0" y="0">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                                    </filter>
                                </defs>
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0' filter="url(#f1)"
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                            </svg>

                            <svg className='filter' width='100%' height="90" viewBox='-2 -2 15 15'>
                                <motion.path d='M10 4 6 6 2 4 2 8 10 8 10 4 2 4'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px'
                                    custom={2} animate='moveEnvelope' variants={variants}
                                />
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                                <defs>
                                    <filter id="f1" x="0" y="0">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                                    </filter>
                                </defs>
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0' filter="url(#f1)"
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                            </svg>

                            <svg className='filter' width='100%' height="90" viewBox='-2 -2 15 15'>
                                <motion.path d='M10 4 6 6 2 4 2 8 10 8 10 4 2 4'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px'
                                    custom={3} animate='moveEnvelope' variants={variants}
                                />
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                                <defs>
                                    <filter id="f1" x="0" y="0">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                                    </filter>
                                </defs>
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0' filter="url(#f1)"
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                            </svg>

                            <svg className='filter' width='100%' height="90" viewBox='-2 -2 15 15'>
                                <motion.path d='M10 4 6 6 2 4 2 8 10 8 10 4 2 4'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px'
                                    custom={4} animate='moveEnvelope' variants={variants}
                                />
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0'
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                                <defs>
                                    <filter id="f1" x="0" y="0">
                                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                                    </filter>
                                </defs>
                                <path className='filter' d='M6 0C-2 0-2 12 6 12 14 12 14 0 6 0' filter="url(#f1)"
                                    fill='transparent' stroke='lightgrey' strokeWidth='0.7px' />
                            </svg>
                        </div>
                        <article >

                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
                        </article>
                    </span>
                </div>
            </div>
        </>
    )
}
export default Contact