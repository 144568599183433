
import { createContext } from "react"

export const TitleAnimate = createContext(
    {
        slide:
            { x: ['-600px', '0px'], transition: { type: 'spring',
            stiffness: 50,
            duration: 1 }}
    }
)




