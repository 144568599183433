import React from "react";
import '../src/styles/style.css';
import { Routes, Route } from "react-router-dom";
import Home from './views/Home'
import AbouMe from "./views/AboutMe";
import MyJob from "./views/MyJob";
import Contact from "./views/Contact";
import Parallax from "./views/Parallax";
import { NavBarF } from "./components/NavBarF";
import { Test } from "./views/Test";

const App = () => {

  return (

    <div className="body-app">
      <div>
        <div>
          <NavBarF />
        </div>
      </div>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/aboutme" element={<AbouMe />} />
        <Route path="/myjob" element={<MyJob />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/parallax" element={<Parallax />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </div>
  );
}

export default App;
