import { AboutMeIcon, ContactIcon, HomeIcon, MyJobIcon, ParallaxIcon } from "./IconsSVG";

export const MenuData = [
    {
        id: 1,
        title: 'Home',
        path: '/',
        icon: <HomeIcon />,
        color: 'red'
    },
    {
        id: 2,
        title: 'About Me',
        path: '/aboutme',
        icon: <AboutMeIcon />,
        color: 'green'
    },
    {
        id: 3,
        title: 'My Job',
        path: '/myjob',
        icon: <MyJobIcon />,
        color: 'blue'
    },
    {
        id: 4,
        title: 'Contact',
        path: '/contact',
        icon: <ContactIcon />,
        color: 'magenta'
    },
    {
        id: 5,
        title: 'Parallax',
        path: '/parallax',
        icon: <ParallaxIcon />,
        color: 'yellow'
    },
]