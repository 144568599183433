import React from "react";
import { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import "../styles/style.css";
import { MenuData } from "../components/MenuData";
import { Link } from "react-router-dom";

/**
 * This is an example of animating between different components in Framer Motion 2.
 *
 * By wrapping a tree with AnimateSharedLayout, children can be given a layoutId.
 *
 * When a component with a layoutId is removed and a new one with the same layoutId
 * is added elsewhere, the new component will animate from the old one.
 *
 * The outline being animated here is four different components, animated like one.
 */

export const Test = () => {
    const [selected, setSelected] = useState(0);
    const clickOnIcon = (id) => {
        setSelected(id)
    }

    return (
        <>
            <AnimateSharedLayout>
                {MenuData.map(tab => (
                    <>                        
                        <Item
                            key={tab.id}
                            color={'yellow'}
                            isSelected={selected === tab.id}                                                        
                        /><Link to={tab.path}>
                        <div onClick={() => clickOnIcon(tab.id)} >
                            {tab.icon}
                        </div>
                        </Link>
                    </>
                ))}
            </AnimateSharedLayout>
        </>
    );
}

function Item({icon, isSelected, onClick }) {
    return (
        <div className="item" onClick={onClick}>
            {isSelected && (
                <motion.div
                    layoutId="outline"
                    className="outline"
                    initial={false}
                    animate={{ borderColor: 'blue' }}
                    transition={spring}
                >{icon}</motion.div>
            )}
        </div>
    );
}
//const colors = [1, 2, 3, 4];
// const colors = ["red", "green", "blue", "magenta"];

const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30
};
