import React from 'react'
import '../styles/style.css'

const fillIcon = 'black'
// Icons on right menu 
export const HomeIcon = () => {
    
    return (
        <div>
            <svg className='filter' width="30" height="30" viewBox='0 0 14 14'
                fill={fillIcon}>                    
                    
                    <path className='filter' d='M12 4 7 1 1 4 2 4 2 11 11 11 11 4 12 4M4 6 9 6 9 10 4 10 4 6' />
                    </svg>
        </div>
    )
}

export const AboutMeIcon = () => {
    return (
        <div>
            <svg width="30" height="30" viewBox='0 0 25 25'
                fill={fillIcon}>
                <path d='M14 8C18 8 18 0 12 0 6 0 6 8 10 8 6 8 2 14 2 20L22 20C22 14 18 8 14 8M10 14 13 14 13 18 14 18 14 19 10 19 10 18 11 18 11 15 10 15 10 14M10 12C10 10 14 10 14 12 14 14 10 14 10 12' />
            </svg>
        </div>
    )
}

export const MyJobIcon = () => {
    return (
        <div>
            <svg width="30" height="30" viewBox='-1 0 16 16'
                fill={fillIcon}>
                <path d='M13 4C12 3 2 3 1 4 0 6 0 9 1 11L13 11C14 9 14 6 13 4M1 4C1 9 13 9 13 4L14 5C13 9 1 9 0 5M4 4C4 2 5 1 5 1 6 0 8 0 9 1 9 1 10 2 10 4L9 2C8 1 6 1 5 2L4 4M6 7C6 9 6 9 6 7L8 7C8 7 8 9 8 9L6 9' />
            </svg>
        </div>
    )
}

export const ContactIcon = () => {
    return (
        <div>
            <svg width="30" height="30" viewBox='1 1 10 10'
                fill='transparent' stroke={fillIcon} strokeWidth='0.5px'>
                <path d='M10 3 6 6 2 3 2 8 10 8 10 3 2 3' />
            </svg>
        </div>
    )
}

export const ParallaxIcon = () => {
    return (
        <div>
            <svg width="30" height="30" viewBox='1 1 10 10'
                fill='black' stroke='transparent' strokeWidth='0.5px'>
                <path d='
                M 1 1 C 3 5 8 5 10 9 C 7 8 4 8 1 9 C 3 5 8 5 10 1 C 7 2 4 2 1 1
                ' />
            </svg>
        </div>
    )
}


