import React, {useContext} from 'react'
import { TitleAnimate } from '../components/TitleAnimate'
import { motion } from "framer-motion"
import image1 from '../images/ChristmasTree1.png'
import image2 from '../images/ChristmasTree2.png'
import image3 from '../images/ChristmasTree3.png'
import image4 from '../images/ChristmasTree4.png'

export const MyJob = () => {
    const variantsTitel = useContext(TitleAnimate)
    return (
        <>
            <div className="body-container">
                <TitleAnimate.Provider value={TitleAnimate}>
                    <motion.div className="item-0" animate='slide' variants={variantsTitel}>
                        <span className="card-item0">
                            <h1><span>My Job Page</span></h1>
                            <h6><span>Lorem ipsum dolor sit amet</span></h6>

                        </span>
                    </motion.div>
                </TitleAnimate.Provider>
                <div className="item-1">
                    <span className="card">
                        {/* <img src='../images/BackGroundBlue.png' alt='img' height={100} width={100} />     */}
                        <div className="thumb" style={{ backgroundImage: `url(${image1})` }}></div>
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-2">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${image2})` }}></div>
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-3">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${image4})` }}></div>
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-4">
                    <span className="card">
                        <div className="thumb" style={{ backgroundImage: `url(${image3})` }}></div>
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        </article>
                    </span>
                </div>
                <div className="item-5">
                    <span className="card">
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
                        </article>
                    </span>
                </div>
            </div>
        </>
    )
}
export default MyJob