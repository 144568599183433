import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { motion, AnimateSharedLayout } from "framer-motion"
import { MenuData } from './MenuData';

export const NavBarF = () => {
    // isOpen a variable to check at what position is menu bar
    const [isOpen, setIsOpen] = useState(false)
    // here is move of the menu bar its width after opening
    const variants = {
        open: { width: '180px', x: '0px', transition: { duration: 1 } },
        closed: { width: '50px', x: '0px', transition: { duration: 1 } }
    }
    // rotation of icon with clue on the top of the navbar
    const variantsRotate = {
        open: { y: '25px', x: '-45px', rotate: -180, transition: { duration: 1 } },
        closed: { y: '0px', x: '0px', rotate: 0, transition: { duration: 1 } }
    }
    // const variantsMenuLinks = {
    //     // Disappearing to change with the descriptions of the next tabs in the menu
    //     open: { opacity: [0, 1], transition: { duration: 1 } },
    //     closed: { opacity: [0, 1], transition: { duration: 1 } },
    // }

    // variable selected to check which tab of menu tabs is selected now
    const [selected, setSelected] = useState(1);
    // method to pass the id of selected menu tab
    // const clickOnIcon = (id) => {
    //     setSelected(id)
    // }

    return (
        <>
            <motion.div className='right-menu'
                animate={isOpen ? "open" : "closed"}
                variants={variants}
            >
                {/* Clue in a circle as a button to open right menu */}
                <div>
                    <motion.svg width="30" height="30" viewBox='-1 -5 20 20'
                        animate={isOpen ? "open" : "closed"}
                        variants={variantsRotate}
                        onClick={() => setIsOpen(isOpen => !isOpen)}
                    >
                        <path d='M5 3 2 6 5 9 5 7 10 7 10 5 5 5 5 3M6 0C-2 0-2 12 6 12 14 12 14 0 6 0'

                            fill='transparent' stroke='grey' />
                    </motion.svg>
                </div>

                {/* Menu TABs with links Link to the pages */}
                <AnimateSharedLayout>
                    <div>
                        {MenuData.map(tab => (
                            <>
                                <Link to={tab.path}>
                                    <Item
                                        key={tab.color}
                                        color={tab.color}
                                        isSelected={selected === tab.color}
                                        onClick={() => setSelected(tab.color)}
                                    />
                                    <div className='menu-tabs-layout' onClick={() => setSelected(tab.color)}>
                                        {isOpen? 
                                            <span className='menu-tabs-text'>{tab.title}</span>:tab.icon                                        
                                        }   
                                        
                                    </div>
                                </Link>
                            </>
                        ))}
                    </div>

                </AnimateSharedLayout>
            </motion.div>
        </>
    )
}

function Item({ color, isSelected, onClick }) {
    return (
        <div className="item" onClick={onClick} style={{ backgroundColor: color }}>
            {isSelected && (
                <motion.div
                    layoutId="outline"
                    className="outline"
                    initial={false}
                    animate={{ borderColor: color }}
                    transition={spring}
                />
            )}
        </div>
    );
}
const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
};








