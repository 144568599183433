import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { motion } from "framer-motion"
import { TitleAnimate } from '../components/TitleAnimate'
import Home1 from '../images/Home1.png'
import Home2 from '../images/Home2.png'
import Home3 from '../images/Home3.png'
import Home4 from '../images/Home4.png'

export const Home = () => {
    const variantsTitel = useContext(TitleAnimate)
    return (
        <>
            <div className="body-container">
                <TitleAnimate.Provider value={TitleAnimate}>
                    <motion.div className="item-0" animate='slide' variants={variantsTitel}>
                        <span className="card-item0">
                            <h1><span>Home Page</span></h1>
                            <h6><span>web.7776.link</span></h6>

                        </span>
                    </motion.div>
                </TitleAnimate.Provider>
                <div className="item-1">
                    <span className="card">
                        <Link to='aboutme'>
                            <div className="thumb" style={{ backgroundImage: `url(${Home1})` }}></div>
                        </Link>
                        <article>
                            <p>The website is responsive. The layout of the elements contained on the website changes depending on the different sizes of devices used by the user.</p>
                        </article>

                    </span>
                </div>
                <div className="item-2">
                    <span className="card">
                        <Link to='myjob'>
                            <div className="thumb" style={{ backgroundImage: `url(${Home2})` }}></div>
                        </Link>
                        <article>
                            <p>Each graphic element and all photos are made by me. For graphic elements I use vector or raster graphics in Gimp or InkScape.</p>
                        </article>
                    </span>
                </div>
                <div className="item-3">
                    <span className="card">
                        <Link to='contact'>
                            <div className="thumb" style={{ backgroundImage: `url(${Home3})` }}></div>
                        </Link>
                        <article>
                            <p>The entire website is embedded on the IONOS server; for Apache2 on Ubuntu 20.04. The costs of such a solution are surprisingly low. € 1 per month for a server and € 0.50 for domain hosting on AWSAmazon.</p>
                        </article>
                    </span>
                </div>
                <div className="item-4">
                    <span className="card">
                        <Link to='parallax'>
                            <div className="thumb" style={{ backgroundImage: `url(${Home4})` }}></div>
                        </Link>
                        <article>
                            <p>This sub page is made with ReactHooks and SCSS (SASS) technologies. With the help of two pictures and a header, a parallax effect was created, that is, the impression of the approaching and receding background.</p>
                        </article>
                    </span>
                </div>
                <div className="item-5">
                    <span className="card">
                        <article>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
                        </article>
                    </span>
                </div>
            </div>
        </>
    )
}
export default Home
